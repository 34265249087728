<template>
  <div class="carouselcontainer">
    <button>
      <a class="prev md:-mt-96" @click="prev" href="#">&#10094;</a>
    </button>
    <div class="carousel1">
      <div
        v-for="(photo, index) in photos"
        :key="index"
        :style="{ transform: `translateX(-${currentIndex * 0}%)` }"
        class="slide1"
      >
        <img
          :src="photo"
          :alt="'Imagen ' + (index + 1)"
          class="w-full h-full object-contain"
        />
      </div>
    </div>
    <button>
      <a class="next md:-mt-96" @click="next" href="#">&#10095;</a>
    </button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      photos: [
        require("../assets/logos/PROGRAMA-DE-PROTECCION-RENAL.jpg"),
        require("../assets/logos/CONSULTA-NEFROLOGÍA.jpg"),
        require("../assets/logos/DIALISIS-PERITONEAL.jpg"),
        require("../assets/logos/HEMODIALISIS.jpg"),

        // Agrega más imágenes según sea necesario
      ],
      currentIndex: 0,
    };
  },
  mounted() {
    this.startCarousel();
  },
  methods: {
    startCarousel() {
      setInterval(this.moveNext, 2500);
    },
    moveNext() {
      this.currentIndex = (this.currentIndex + 1) % this.photos.length;
      this.photos.push(this.photos.shift());
    },
    movePrev() {
      this.currentIndex =
        (this.currentIndex - 1 + this.photos.length) % this.photos.length;
    },
  },
};
</script>
<style>
.prev,
.next {
  cursor: pointer;
  width: auto;
  padding: 6px;
  color: rgb(243, 134, 0);
  font-weight: bold;
  font-size: 28px;
  transition: 0.7s ease;
  border-radius: 100px;
  text-decoration: none;
  user-select: none;
}

.next {
  right: 0;
}

.prev {
  left: 0;
}

.prev:hover,
.next:hover {
  background-color: rgba(100, 99, 99, 0.9);
}
.carouselcontainer {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
}

.carousel1 {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
}

.slide1 {
  min-width: 0%;
}

.slide1 img {
  width: 95%;
  height: 100%;
  object-fit: cover;
}
</style>

<template>
  <div class="carousel-container">
    <div class="carousel">
      <div
        v-for="(photo, index) in photos"
        :key="index"
        :style="{ transform: `translateX(-${currentIndex * 0}%)` }"
        class="slide"
      >
        <img
          :src="photo"
          :alt="'Imagen ' + (index + 1)"
          class="w-full h-full object-contain"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      photos: [
        require("../assets/empresas/01.png"),
        require("../assets/empresas/02.png"),
        require("../assets/empresas/03.png"),
        require("../assets/empresas/04.png"),
        require("../assets/empresas/05.png"),
        require("../assets/empresas/06.png"),
        require("../assets/empresas/07.png"),
        require("../assets/empresas/08.png"),

        // Agrega más imágenes según sea necesario
      ],
      currentIndex: 0,
    };
  },
  mounted() {
    this.startCarousel();
  },
  methods: {
    startCarousel() {
      setInterval(this.moveNext, 2500);
    },
    moveNext() {
      this.currentIndex = (this.currentIndex + 1) % this.photos.length;
      this.photos.push(this.photos.shift());
    },
    movePrev() {
      this.currentIndex =
        (this.currentIndex - 1 + this.photos.length) % this.photos.length;
      // this.photos.unshift(this.photos.pop());
    },
  },
};
</script>
<style>
.carousel-container {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
}

.carousel {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
}

.slide {
  min-width: 10px;
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>

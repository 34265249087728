<template>
  <div class="text-center text-black">
    <div>
      <div class="grid max-w-3x1 gap-1 px-1 sm:grid-cols-2 bg-white">
        <div class="grid">
          Nombre
          <div
            class="bg-white flex min-h-[60px] flex-col-reverse justify-center rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:shadow-inner"
          >
            <input
              type="text"
              name="first-name"
              id="first-name"
              class="peer block w-full border-0 p-0 text-base text-gray-900 placeholder-gray-400 focus:ring-0"
              placeholder="Ejemplo:Andres Felipe Medina"
            />
            <label
              html="first-name"
              class="block transform text-xs font-bold uppercase text-gray-400 transition-opacity, duration-200 peer-placeholder-shown:h-0 peer-placeholder-shown:-translate-y-full peer-placeholder-shown:opacity-0"
            ></label>
          </div>
        </div>
        <div class="grid">
          Correo
          <div
            class="bg-white first:flex min-h-[60px] flex-col-reverse justify-center rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:shadow-inner"
          >
            <input
              type="text"
              name="last-name"
              id="last-name"
              class="peer block w-full border-0 p-0 text-base text-gray-900 placeholder-gray-400 focus:ring-0"
              placeholder="Ejemplo:andres@gmail.com"
            />
            <label
              html="last-name"
              class="block transform text-xs font-bold uppercase text-gray-400 transition-opacity, duration-200 peer-placeholder-shown:h-0 peer-placeholder-shown:-translate-y-full peer-placeholder-shown:opacity-0"
              >Celular</label
            >
          </div>
        </div>
        <div class="grid">
          Celular
          <div
            class="bg-white flex min-h-[60px] flex-col-reverse justify-center rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:shadow-inner"
          >
            <input
              type="text"
              name="last-name"
              id="last-name"
              class="peer block w-full border-0 p-0 text-base text-gray-900 placeholder-gray-400 focus:ring-0"
              placeholder="Ejemplo:839 317 5834"
            />
            <!-- <label
              html="company"
              class="block transform text-xs font-bold uppercase text-gray-400 transition-opacity, duration-200 peer-placeholder-shown:h-0 peer-placeholder-shown:-translate-y-full peer-placeholder-shown:opacity-0"
              >Company</label
            > -->
          </div>
        </div>
        <div class="grid">
          Dirección
          <div
            class="bg-white flex min-h-[60px] flex-col-reverse justify-center rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:shadow-inner"
          >
            <input
              type="email"
              name="email"
              id="email"
              class="peer block w-full border-0 p-0 text-base text-gray-900 placeholder-gray-400 focus:ring-0"
              placeholder="Calle20 #13-64"
            />
            <!-- <label
              html="email"
              class="block transform text-xs font-bold uppercase text-gray-400 transition-opacity, duration-200 peer-placeholder-shown:h-0 peer-placeholder-shown:-translate-y-full peer-placeholder-shown:opacity-0"
              >E-mail</label
            > -->
          </div>
        </div>
        <div class="grid">
          Mensaje
          <div
            class="bg-white flex min-h-[60px] flex-col-reverse justify-center rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:shadow-inner"
          >
            <input
              type="email"
              name="email"
              id="email"
              class="peer block w-full border-0 p-0 text-base text-gray-900 placeholder-gray-400 focus:ring-0"
              placeholder="Describa el motivo de su cita"
            />
            <label
              html="email"
              class="block transform text-xs font-bold uppercase text-gray-400 transition-opacity, duration-200 peer-placeholder-shown:h-0 peer-placeholder-shown:-translate-y-full peer-placeholder-shown:opacity-0"
              >E-mail</label
            >
          </div>
        </div>
        <div class="grid">
          Digite este código:1030
          <div
            class="bg-white flex min-h-[60px] flex-col-reverse justify-center rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:shadow-inner"
          >
            <input
              type="email"
              name="email"
              id="email"
              class="peer block w-full border-0 p-0 text-base text-gray-900 placeholder-gray-400 focus:ring-0"
              placeholder="Verificación"
            />
            <label
              html="email"
              class="block transform text-xs font-bold uppercase text-gray-400 transition-opacity, duration-200 peer-placeholder-shown:h-0 peer-placeholder-shown:-translate-y-full peer-placeholder-shown:opacity-0"
              >Correo</label
            >
          </div>
        </div>
        <button
          type="submit"
          class="mt-4 bg-orange-500 text-white py-2 px-6 rounded-md hover:bg-orange-600"
        >
          Enviar
        </button>
      </div>
    </div>
  </div>
</template>
